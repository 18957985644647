import React, { useEffect } from 'react'

import Script from 'next/script'

import { RISK_ID } from 'lib/@getethos/experiments/feature_constants'
import { AgentsIFrameScript } from 'lib/@getethos/scripts/agentsIFrameScript'
import { useFeatureVariable } from 'lib/FeaturesAndExperiments/getFeatureVariable'
import PropTypes from 'prop-types'

import useSiteAppUrl from '@/hooks/useSiteAppUrl'

/**
 * Component renders a set of non-critical blocking scripts below the footer
 *
 * @public
 *
 * @example ```
 *  <CmsFooterScripts />
 * ```
 * @return {JSX.Element}
 *
 * @see {https://nextjs.org/docs/pages/api-reference/components/script}
 */
export const CmsFooterScripts = ({
  hideAccessibilityWidget,
  shouldLoadAgentsIFrameScript,
}: any) => {
  const siteAppUrl = useSiteAppUrl()

  if (!siteAppUrl) {
    throw new TypeError('siteAppUrl is undefined')
  }

  const attributionUrl = `https://${siteAppUrl}/attribution.bundle.js`

  const clientId =
    process.env.NEXT_PUBLIC_ETHOS_ENV === 'production'
      ? '1sjo3x1clg5er0ahown6fjnjpbl10ntu'
      : 'cnscg57iq35u5ooyx7iytuz4rgt6wjup'

  // Risk ID
  const { isEnabled: riskIdEnabled } = useFeatureVariable(RISK_ID)
  useEffect(() => {
    if (!riskIdEnabled) return
    const transmitRiskToken = () => {
      if (!window.myTSAccountProtection) {
        window.myTSAccountProtection = new TSAccountProtection(clientId, {
          serverPath: 'https://trsmit.ethoslife.com',
        })
        window.myTSAccountProtection.init()
      }
    }

    document.addEventListener('TSAccountProtectionReady', transmitRiskToken)
    return () => {
      document.removeEventListener(
        'TSAccountProtectionReady',
        transmitRiskToken
      )
    }
  }, [clientId, riskIdEnabled])

  // async & defer will fallback to defer on browsers that do not support async
  // Scripts are set as defer (instead of async) to be parsed after DOM load

  // Scripts will be evaluated in the order they appear - place the least important
  // scripts at the bottom
  return (
    <>
      {attributionUrl && (
        <Script
          type="text/javascript"
          src={attributionUrl}
          strategy="lazyOnload"
        />
      )}
      {!hideAccessibilityWidget && (
        <Script
          data-account="i91M6vRXVv"
          src="https://cdn.userway.org/widget.js"
          data-trigger="universal-access-menu-trigger"
          strategy="lazyOnload"
        />
      )}
      {shouldLoadAgentsIFrameScript && AgentsIFrameScript()}
    </>
  )
}

CmsFooterScripts.propTypes = {
  hideAccessibilityWidget: PropTypes.bool,
  shouldLoadAgentsIFrameScript: PropTypes.bool,
}
