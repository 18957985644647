import { COVERAGE_SLIDER, FINAL_EXPENSE_COVERAGE } from '@/constants'
import { useEligibility } from '@/shared/Estimate/useEligibility'
import { useRegionFromZipCode } from '@/shared/Estimate/useRegionFromZipCode'
import { getMedianTerm, getMinValidCoverage } from '@/shared/Estimate/utils'
import { estimateWidgetAnalytics } from 'lib/@getethos/analytics/analyticsEvents'
import { CreditScore, Product } from 'lib/@getethos/constants'
import { maxCoverageDetermination } from 'lib/@getethos/utils/maxCoverageDetermination'
import { predictProduct } from 'lib/@getethos/utils/predictProduct'

import { useEwUpdates } from '@/hooks/features/useEwUpdates'

import { UserData } from '../types/user'

const defaultTerm = 10
const defaultTerms = [10, 15, 20, 30]

interface IUseGetRecommendations {
  userData: UserData
  featureFlagConfig?: Record<string, boolean>
  experimentCoverage?: number
}

const getEwUpdatesCoverage = (birthDate: number) => {
  // if user is <50, use 500k
  // if user is >=50, <60, use 500k
  // if user is >60 and <=65, use 250k
  // if user is >65, use 15k
  if (birthDate < 50 || (birthDate >= 50 && birthDate < 60)) {
    return 500000
  }
  if (birthDate >= 60 && birthDate <= 65) {
    return 250000
  }
  if (birthDate > 65) {
    return 15000
  }
}

const getRecommendedCoverage = ({
  birthDate,
  desiredCoverage,
  featureFlagConfig,
  maxCoverage,
  minCoverage,
  product,
}: {
  birthDate: number
  desiredCoverage: number
  featureFlagConfig: Record<string, boolean>
  maxCoverage: number
  minCoverage: number
  product: Product
}) => {
  if (featureFlagConfig?.finalExpense) return 5000

  if (!Number.isNaN(desiredCoverage)) {
    if (desiredCoverage > 2_000_000) return 2_000_000
    if (desiredCoverage <= maxCoverage) return desiredCoverage
    return maxCoverage
  }

  const ewUpdatesCoverage = getEwUpdatesCoverage(birthDate)
  if (ewUpdatesCoverage && featureFlagConfig.useEwUpdates) {
    return ewUpdatesCoverage
  }

  const isMedianValues = featureFlagConfig?.useMedianValues
  const median = (maxCoverage + minCoverage) / 2

  if (isMedianValues) {
    return product === Product.FE ? Math.round(median / 1000) * 1000 : median
  }

  switch (product) {
    case Product.Term:
    case Product.GI:
    case Product.SI:
      return COVERAGE_SLIDER.MIN
    case Product.FE:
      return FINAL_EXPENSE_COVERAGE.MIN
  }
}

const getRecommendedTerm = (
  terms: number[],
  featureFlagConfig: Record<string, boolean>,
  birthDate: number
) => {
  const isMedianValues = featureFlagConfig?.useMedianValues
  const isEwUpdates = featureFlagConfig?.useEwUpdates

  if (isEwUpdates) {
    if (birthDate < 50) {
      return 20
    }
    return 10
  }

  if (isMedianValues) {
    return getMedianTerm(terms)
  }

  return terms[0]
}

export const useGetRecommendations = ({
  userData: { birthDate, region, zipCode, estimatedCredit, desiredCoverage },
  featureFlagConfig,
}: IUseGetRecommendations) => {
  const { region: prefillZipCode } = useRegionFromZipCode(
    estimateWidgetAnalytics,
    zipCode
  )

  const shouldUseEwUpdates = useEwUpdates()
  const regionToUse = region || prefillZipCode

  if (region === 'NY') {
    window.location.assign(`/life/youre-in-new-york/`)
  }

  const { terms } = useEligibility(birthDate)
  try {
    const product = predictProduct({
      birthDate,
      creditScore: (estimatedCredit as CreditScore) || null,
      region: regionToUse || null,
    })
    const config = {
      useMedianValues: featureFlagConfig?.useMedianValues || false,
      finalExpense: featureFlagConfig?.finalExpense || false,
      useEwUpdates: shouldUseEwUpdates,
    }

    console.log('config', config)

    const maxCoverage = maxCoverageDetermination(
      birthDate,
      product,
      config.finalExpense
    )
    const minCoverage = getMinValidCoverage(product)

    const recommendedCoverage = getRecommendedCoverage({
      birthDate: Number(birthDate),
      desiredCoverage: Number(desiredCoverage),
      featureFlagConfig: config,
      maxCoverage,
      minCoverage,
      product,
    })

    const recommendedTerm = getRecommendedTerm(terms, config, Number(birthDate))

    return {
      recommendedTerm,
      recommendedCoverage,
      maxCoverage,
      minCoverage,
      product,
      region: regionToUse,
      terms,
    }
  } catch (error) {
    return {
      recommendedTerm: defaultTerm,
      recommendedCoverage: COVERAGE_SLIDER.MIN,
      maxCoverage: 2000000,
      minCoverage: COVERAGE_SLIDER.MIN,
      product: Product.Term,
      region: 'AL',
      terms: defaultTerms,
    }
  }
}
