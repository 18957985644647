import { WILLS_30_DAY_TRIAL_FLOW } from 'lib/@getethos/experiments/constants'
import { useDecisionForFlag } from 'lib/FeaturesAndExperiments/getDecisionForFlag'

const features = {
  control: 'https://app.tomorrow.me/documents/will/intro',
  curatedFluffFlow: 'wills',
  minimalFlow: 'wills-minimal',
}

export const THIRTY_DAY_TRIAL_FLOW = 'wills-30-day-trial'
export const WILLS_ONBOARDING_FLOW = 'wills-onboarding'

// TODO: these can be removed for baselining
export const WILLS_PAINTED_DOOR_V2_SEPARATE_FLOW = 'wills-pdv2-separate'
export const TRUSTS_PAINTED_DOOR_V2_SEPARATE_FLOW = 'trusts-pdv2-separate'
export const WILLS_PAINTED_DOOR_V2_2_1_TREATMENT_1 = 'wills-pdv2-2-1-t1'

export const useWillsFunnelFlow = () => {
  const { variation, isLoading } = useDecisionForFlag({
    name: WILLS_30_DAY_TRIAL_FLOW.EXPERIMENT_KEY,
    fallbackVariation: WILLS_30_DAY_TRIAL_FLOW.VARIATIONS.CONTROL,
  })

  let flow
  let isTreatment = false

  switch (variation) {
    case WILLS_30_DAY_TRIAL_FLOW.VARIATIONS.TRIAL_TREATMENT:
      flow = WILLS_ONBOARDING_FLOW
      isTreatment = true
      break
    case WILLS_30_DAY_TRIAL_FLOW.VARIATIONS.V_2_2_1_TREATMENT_1:
      flow = THIRTY_DAY_TRIAL_FLOW
      isTreatment = true
      break
    default:
      flow = undefined
  }

  return {
    isLoading,
    isTreatment,
    isLeadForm: false,
    flow,
  }
}
